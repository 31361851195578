/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
'use client'

import { ErrorBoundary } from '@sentry/nextjs'
import { type ReactNode, Suspense, use, useTransition } from 'react'
import { useEffectOnce } from 'react-use'
import { useCart } from './cart-context'
import { type UserCart } from './cart-types'

export const CartFiller = ({
  cartPromise,
  children,
}: {
  cartPromise: Promise<UserCart | false>
  children?: ReactNode
}) => {
  return (
    <ErrorBoundary>
      <Suspense>
        <CartFillerSafe cartPromise={cartPromise} />
        {children}
      </Suspense>
    </ErrorBoundary>
  )
}

const CartFillerSafe = ({
  cartPromise,
}: {
  cartPromise: Promise<UserCart | false>
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isPending, startTransaction] = useTransition()
  const { setCart } = useCart()

  let userCart: UserCart | undefined
  const cartResult = use(cartPromise)

  if (cartResult !== false) {
    userCart = cartResult
  }

  useEffectOnce(() => {
    startTransaction(() => {
      setCart(userCart)
    })
  })

  return <></>
}
